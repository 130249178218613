<template>
  <layout>
    <div id="main-container">
      <div class="contentWrapper shadow-lg">
        <ul class="articleList   font-black text-black">
          <li>
            <a href="#"
              ><i class="icon-article-doc"></i
              ><span
                >Why do I need to share my photo and CNIC to create my
                wallet?</span
              ></a
            >
          </li>
          <li>
            <a href="#"
              ><i class="icon-article-doc"></i
              ><span
                >My wallet is pending or on hold. What does that mean?</span
              ></a
            >
          </li>
          <li>
            <a href="#"
              ><i class="icon-article-doc"></i><span>What is an MPIN?</span></a
            >
          </li>
          <li>
            <a href="#"
              ><i class="icon-article-doc"></i
              ><span>How can I upgrade my wallet?</span></a
            >
          </li>
        </ul>
      </div>
    </div>
  </layout>
</template>

<script>
import layout from "../../../components/Categories/layout.vue";
export default {
  components: { layout },
};
</script>

<style scoped>
li{
    border-bottom: #858585 1px solid;
}
.contentWrapper {
  background: #fff;
  border: 1px solid #e9e5e5;

  min-height: 460px;
  padding: 45px 46px;
  position: relative;
}

.articleList{
    font-size: 3rem;
    font-weight: 600;
}
</style>
